<template>
  <v-main>
    <v-container>
      <v-container style="height: 10vh"></v-container>
      <v-row justify="center" style="margin-bottom: 3vh">
        <v-avatar size="200">
          <img
            src="@/assets/rs_logo.png"
            alt=""
          >
        </v-avatar>
      </v-row>
      <v-row justify="center" class="my-5"><h3 class="font-weight-medium">LINEでログイン</h3></v-row>
      <v-row align="center" justify="center" class="my-5">
        <img 
          id="lineBtn"
          src="@/assets/LineLoginButtonImage/images/DeskTop/1x/44dp/btn_login_base.png"
          alt=""
          @click="onLineBtnClick"
          @mouseover="onLineBtnHover"
          @mouseleave="onLineBtnLeave"
          @mousedown="onLineBtnPress"
          @mouseup="onLineBtnUp">
      </v-row>
      <v-row justify="center" style="margin-top: 10vh">
        サービスのご利用開始前には以下のプライバシーポリシーを十分にお読みいただき、同意の上ご利用お願いします。
      </v-row>
      <v-row justify="center" style="margin-bottom: 10vh">
        <v-btn 
          text
          color="primary"
          @click="$router.push('/privacy_policy')"
        >
          プライバシーポリシー
        </v-btn>
      </v-row>

    <!-- on progress -->
    <v-overlay :value="onProgress">
      <v-progress-circular indeterminate color="primary" ></v-progress-circular>
    </v-overlay>

    <!-- エラー -->
    <v-snackbar v-model="errorVisible" :timeout="errorVisibleTimeout" color="red darken-2" top>
      <h3 v-if="errorVisible">{{ this.error.message }}</h3>
    </v-snackbar>
    </v-container>
  </v-main>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, alphaNum } from 'vuelidate/lib/validators'
import store from "@/store";
import firebase from 'firebase';
import "firebase/auth";

export default {
  name: 'SignIn',
  mixins: [validationMixin],
  validations: {
    email: { required, email, maxLength: maxLength(128) },
    password: {
      required,
      alphaNum,
      minLength: minLength(6),
      maxLength: maxLength(32)
    },
  },
  data () {
    return {
      email: '',
      password: '',
      show1: false,
      onProgress: false,
      error: null,
      errorVisible: false,
      errorVisibleTimeout: 7000
    }
  },
  computed: {
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.maxLength && errors.push('email must be at most 128 characters long')
      !this.$v.email.required && errors.push('email is required.')
      !this.$v.email.email && errors.push('email must be in xxx@xxx format.')
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.maxLength && errors.push('password must be at most 32 characters long')
      !this.$v.password.required && errors.push('password is required.')
      !this.$v.password.alphaNum && errors.push('password must be composed of alphanumerics.')
      return errors
    },
  },
  methods: {
    signIn() {
      this.onProgress = true
      firebase.auth().signInWithEmailAndPassword(this.email, this.password)
      .then((res) => {
        var user = res.user
        this.onProgress = false
        store.commit("onSignedIn", user)
        this.$router.push('/map')
      })
      .catch((err) => {
        // アカウントがないとき
        this.onProgress = false
        this.error = {message: err.message}
        this.errorVisible = true
      });
    },
    onLineBtnHover: function() {
      var elem = document.getElementById("lineBtn");
      elem.src = require("@/assets/LineLoginButtonImage/images/DeskTop/1x/44dp/btn_login_hover.png")
    },
    onLineBtnLeave: function() {
      var elem = document.getElementById("lineBtn");
      elem.src = require("@/assets/LineLoginButtonImage/images/DeskTop/1x/44dp/btn_login_base.png")
    },
    onLineBtnPress: function() {
      console.log("press")
      var elem = document.getElementById("lineBtn");
      elem.src = require("@/assets/LineLoginButtonImage/images/DeskTop/1x/44dp/btn_login_press.png")
    },
    onLineBtnUp: function() {
      var elem = document.getElementById("lineBtn");
      elem.src = require("@/assets/LineLoginButtonImage/images/DeskTop/1x/44dp/btn_login_hover.png")
    },
    onLineBtnClick() {
      this.onProgress = true
      var url = process.env.VUE_APP_API_ORIGIN + "/api/line_login/auth"
      fetch(url, {
        headers: {}
      })
      .then(res => {
        return res.json()
      })
      .then(data => {
        this.onProgress = false
        location.href = data.url
      })
    },
  },
  mounted() {
  }
}
</script>

<style>
</style>